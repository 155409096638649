import { AES, enc } from "crypto-js";
import toast from "react-hot-toast";
import { onUserLogout } from "../../store/AuthReducer";
import store from "../../store/Store";
import { cookieKeys, localStorageKeys, toasterPosition } from "../constants/Constants";

export const toastSuccess = (message: string) => {
  toast.success(message, { position: toasterPosition, style: { color: '#000', minWidth: 150, padding: 10, fontWeight: 500, marginBottom: 60, border: '1px solid #073E84' }, iconTheme: { primary: '#073E84 ', secondary: '#fff' } });
};

export const toastError = (message: string) => {
  toast.error(message, { position: toasterPosition, style: { color: '#000', fontWeight: 500, padding: 10, marginBottom: 60, border: '1px solid #ff0000' } });
};

const getCookie = (cookieName: any) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const decryptData = (data: any) => {
  const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(enc.Utf8));
  }
};

export const getEncryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptData(cookieData);
    }
  }
};

export const handleLogout = () => {
  localStorage.clear();
  store.dispatch(onUserLogout(false));
};

export const encryptData = (data: any) => {
  return AES.encrypt(JSON.stringify(data), cookieKeys.cryptoSecretKey);
};
export const setEncryptedLocalStorage = (key: string, value: any) => {
  const encryptedString = encryptData(value);
  localStorage.setItem(key, encryptedString.toString());
};

export const getDecryptedLocalStorage = (key: string) => {
    const localStorageData = localStorage.getItem(key);
    if (localStorageData) {
      return decryptData(localStorageData);
    } else {
      localStorage.clear();
    }
};

export const getInitialDashBoardCounts = () => {
  const counts = getDecryptedLocalStorage(localStorageKeys.dashboardDataCounts);
  let result = {
    data: {},
    isEmpty: true
  }
  if (counts) {
    result.data = counts;
    result.isEmpty = false;
  } else {
    result.data = { txCount: 0, peerCount: 0, latestBlock: 0, chaincodeCount: 0 }
  }
  return { result };
}
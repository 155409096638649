import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    kycRejectModalOpenStatus: false
};

const loadingReducer = createSlice({
    name: "loadingReducer",
    initialState,
    reducers: {
        setLoading: (state,action) => {
            state.isLoading = action.payload;

        },
        setKycModalOpenStatus:(state,action)=>{
            state.kycRejectModalOpenStatus = action.payload;
        },
    },
  });

  export const {setKycModalOpenStatus,setLoading} = loadingReducer.actions
  export default loadingReducer.reducer
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { ReactComponent as Closeicon } from "../../assets/images/close-icon.svg";
import { ReactComponent as Logo } from "../../assets/images/edexa-logo.svg";
import { ReactComponent as Menuicon } from "../../assets/images/menu-icon.svg";
import "../../assets/scss/layouts/header.scss";

export const Header = () => {
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };
    return (
        <div className="header white-bg d-flex flex-wrap align-items-center justify-content-between p-3">
            <div className="leftpart d-flex flex-wrap align-items-center">
                <RouterLink to="/" className="headerlogo pe-3 borderRight-1px border-gray">
                    <Logo className="logo" />
                </RouterLink>
                <div className="header-title ps-3 f-20px f-bold dark-color">Explorer</div>
            </div>
            <div className="toggle-button d-md-none d-block" onClick={toggleClass} >
                <Menuicon className="menu-toggle" />
            </div>
            <div className={`mein-menu d-md-flex flex-md-wrap align-items-md-center ${isActive ? "show-menu" : ""}`}>
                <div className="menu-close position-absolute blue-bg d-md-none d-flex flex-wrap align-items-center justify-content-center" onClick={toggleClass}>
                    <Closeicon className="fill-white" />
                </div>
                <div className="menu f-16px pt-md-0 pt-5 pb-md-0 pb-3">
                    <div className="d-md-flex flex-md-wrap align-items-md-center">
                    </div>
                </div>
            </div>
        </div>
    )
}
import { createSlice } from "@reduxjs/toolkit";
import { onLogin } from "../actions/Auth";
import { localStorageKeys } from "../utils/constants/Constants";

const getUserToken = () => {
    const userToken = localStorage.getItem(localStorageKeys.userToken) || '';
    if (userToken) {
        return {
            isLoggedIN: true,
            userToken
        }
    } else {
        return {
            isLoggedIN: false,
            userToken: null
        }
    }
}

const authReducer = createSlice({
    name: "AuthSlice",
    initialState: getUserToken(),
    reducers: {
        onUserLogIn: (state, action) => {
            state = {
                userToken: action.payload.userToken,
                isLoggedIN: true
            }
        },
        onUserLogout: (state,action) => {
            state = {
                userToken: null,
                isLoggedIN: false
            }
        }, onLoginAfterTokenExpiry: () => {
            onLogin().then((response) => {
                const userToken = `Bearer ${response.data.token}`;
                if (userToken) {
                    localStorage.setItem(localStorageKeys.userToken, userToken);
                    window.location.reload();
                }
            })
        }
    },
});

export const { onLoginAfterTokenExpiry, onUserLogIn, onUserLogout } = authReducer.actions
export default authReducer.reducer
export const defaultReceipt = "bStamp";
export const toasterPosition = "bottom-left";
export const searchHashLengthValidation = 64;

export const cookieKeys = {
    cryptoSecretKey: "edexaUser",
    cookieInitial: "edexa",
    cookieUser: "CookieUser",
};

export const localStorageKeys = {
    userInfo: "userInfo",
    userToken: "userToken",
    dashboardDataCounts: "dashboardDataCounts",
    graphValues: "graphValues"
};

export const validationMessages = {
    onlyAlphabets: "Please enter alphabets only",
    onlyNumerical: "Please enter numerical value",
    emptySearch: "Please enter a hash",
    invalidHash: "Please enter valid transaction ID",
    connectionTimeOut: "Connection Timed Out"
};

export const dashboardGraphLabels = [
    { label: "Tx/Hour", value: "transactionByHour", combineNodes: 3 },
    { label: "Tx/Min", value: "transactionByMinute", combineNodes: 7 },
    { label: "Blocks/Hour", value: "blocksByHour", combineNodes: 3 },
    { label: "Blocks/Min", value: "blocksByMinute", combineNodes: 7 },
]
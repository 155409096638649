import authReducer from "./AuthReducer";
import channelDetailsReducer from "./ChannelsReducer";
import loadingReducer from "./LoaderReducer";

const rootReducer = {
    auth: authReducer,
    loading: loadingReducer,
    channels: channelDetailsReducer
};

export default rootReducer;


import { createSlice } from "@reduxjs/toolkit";
import { defaultReceipt } from "../utils/constants/Constants";
import { getInitialDashBoardCounts } from "../utils/functions/CommonFunctions";

const initialState = {
    channelList: [],
    currentChannel: defaultReceipt,
    channel_genesis_hash: "a7c8a6615070ab0bf1d8ef92ab675ae5aac0d8e47b080558bde168d0d8029142",
    searchHashResponse: null,
    channelCounts: getInitialDashBoardCounts().result.data
};

const channelDetailsReducer = createSlice({
    name: "channelDetailsReducer",
    initialState,
    reducers: {
        setChannelList: (state, action) => {
            state.channelList = action.payload;
        },
        setCurrentReceipt: (state, action) => {
            state.currentChannel = action.payload;
        },
        setCurrentChannelHash: (state, action) => {
            state.channel_genesis_hash = action.payload;

        },
        dispatchSearchResult: (state, action) => {
            state.searchHashResponse = action.payload;

        },
        setChannelCounts: (state, action) => {
            state.channelCounts = action.payload;

        },
    },
});

export const { dispatchSearchResult, setChannelCounts, setChannelList, setCurrentChannelHash, setCurrentReceipt } = channelDetailsReducer.actions
export default channelDetailsReducer.reducer
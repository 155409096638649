import { useState } from "react";
import "./login.css";

export const Login = () => {
    const [loginDetails, setLoginDetails] = useState({
        user: "",
        password: "",
        network: "first-network"
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
    }
    return <div className="custom-background">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <form onSubmit={handleSubmit} className="box">
                            <h1>Login</h1>
                            <p className="text-muted"> Please enter your login and password!</p>
                            <input type="text" name="username" placeholder="Username" autoComplete="off" onChange={(e) => setLoginDetails({ ...loginDetails, user: e.target.value })} />
                            <input type="password" name="" placeholder="Password" autoComplete="off" onChange={(e) => setLoginDetails({ ...loginDetails, password: e.target.value })} />
                            <input type="submit" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>




}
import { useState } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
} from "reactstrap";
import { searchTransaction } from "../../actions/Dashboard";
import { ReactComponent as Searchicon } from "../../assets/images/search_icon.svg";
import "../../assets/scss/layouts/search-bar.scss";
import { dispatchSearchResult } from "../../store/ChannelsReducer";
import { setLoading } from "../../store/LoaderReducer";
import {
  searchHashLengthValidation,
  validationMessages,
} from "../../utils/constants/Constants";
import { toastError } from "../../utils/functions/CommonFunctions";

export const SearchBar = () => {
  const { channel_genesis_hash, channelCounts } = useSelector(
    (state: any) => state.channels
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (search) {
      if (search.length !== searchHashLengthValidation) {
        return toastError(validationMessages.invalidHash);
      }
      dispatch(setLoading(true));
      searchTransaction(channel_genesis_hash, search)
        .then((response:any) => {
          dispatch(setLoading(false));
          if (response.status === 200) {
            dispatch(dispatchSearchResult(response.row));
            navigate("/search");
          }
        })
        .catch(() => {
          setSearch("");
        });
    } else {
      toastError(validationMessages.emptySearch);
    }
  };

  const handleChange = (event: any) => {
    setSearch(event.target.value.trim());
  };

  const searchCategories = [
    { label: "Transaction", selected: true },
    { label: "Address", selected: false },
    { label: "Block", selected: false },
    { label: "Tag", selected: false },
  ];

  return (
    <div className="searchbar position-relative">
      <div className="searchbar-backgound position-absolute blue-bg w-100"></div>
      <div className="container">
        <div className="title-wrapper d-flex flex-wrap align-items-center justify-content-between white-color mb-xl-4 mb-3">
          <div className="searchbar-title f-30px f-bold">
            edeXa Permissioned Block Explorer
          </div>
          {channelCounts.latestBlock > 0 && (
            <div className="info">
              up to{" "}
              {
                <CountUp
                  start={channelCounts.latestBlock}
                  end={+channelCounts.latestBlock + 10}
                  duration={1}
                />
              }{" "}
              Blocks Number
            </div>
          )}
        </div>
        <div className="search-wrapper">
          <InputGroup>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="f-16px f-medium dropdown-custom dropdown-lightgray"
              addonType="prepend"
            >
              <DropdownToggle caret>
                Transaction
              </DropdownToggle>
              <DropdownMenu>
                {searchCategories.map((category, index) => {
                  return (
                    <DropdownItem key={index} disabled={!category.selected}>
                      {category.label}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Input
              placeholder="Search address, block, transaction, tag ..."
              value={search}
              onChange={handleChange}
            />
            <NavLink onClick={handleSearch} to="">
              <Button
                block
                addonType="append"
                className="custom-button lightblue-button lightblue-bg hover-blue-bg white-color"
              >
                <span className="d-md-block d-none">Search</span>
                <Searchicon className="d-md-none d-block" />
              </Button>
            </NavLink>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

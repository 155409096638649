export const apiEndPoint ={
    loginAPI : "auth/login",
    channelInfoAPI : "channels/info",
    channelListAPI : "channels",
    channelCountsAPI : "status",
    blockActivityAPI : "blockActivity",
    transactionPerHourAPI : "txByHour",
    transactionPerMinuteAPI : "txByMinute",
    blockByHourAPI : "blocksByHour",
    blockByMinuteAPI : "blocksByMinute",
    searchTransactionAPI : "transaction",
    widgetBlocksApi : "block-hash",
    tableBlocksApi : "hlf/transactions?limit=10&page=1",
}
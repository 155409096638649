import { Outlet } from "react-router-dom";
import { Dashboard } from "../../pages/Dashboard";
import { Login } from "../../pages/Login";
import { Search } from "../../pages/Search";

export const privateRoutes = [
    {
        path: "/",
        element: <Outlet />,
        children: [
            {
                path: "/",
                element: <Dashboard />,
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/search",
                element: <Search />
            },
            {
                path: "/search/:txid",
                element: <Search />
            }]
    }
];

export const publicRoutes = [
    {
        path: "/",
        element: <Outlet />,
        children: [
            {
                path: "/",
                element: <Login />,
            },
            {
                path: "/login",
                element: <Login />,
            },]
    }
];

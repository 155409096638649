export const environment = {
  apiUrl: "https://hlf-breceipts.edexa.com/",
  newApiUrl: "https://edexawebsiteapi.io-world.com/",
  newApiUrlTwo: "https://apiexplorer.io-world.com/",
  terms_conditions: "https://edexa.network/terms-and-conditions",
  privacy_policy: "https://edexa.network/privacy-policy",
  loginParams: {
    user: "exploreradmin",
    password: "exploreradminpw",
    network: "first-network",
  },
};

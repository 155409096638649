import moment from "moment";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlockActivityList,
  getBlockWidgetInfo,
  getBlocksByHourData,
  getBlocksByMinuteData,
  getChannelCounts,
  getChannelInfo,
  getChannelList,
  getTransactionByHourData,
  getTransactionByMinuteData,
} from "../actions/Dashboard";
import { ReactComponent as Upicon } from "../assets/images/up-arrow-long.svg";
import "../assets/scss/layouts/dashboard.scss";
import "../assets/scss/main.scss";
import { BlockDetailModel } from "../components/BlockDetailModel";
import { BlockListSectionComponent } from "../components/BlockListSection";
import { chartInitialState } from "../components/ChartSchema";
import { setChannelCounts, setChannelList, setCurrentChannelHash } from "../store/ChannelsReducer";
import { setLoading } from "../store/LoaderReducer";
import {
  dashboardGraphLabels,
  localStorageKeys,
} from "../utils/constants/Constants";
import {
  getInitialDashBoardCounts,
  setEncryptedLocalStorage,
} from "../utils/functions/CommonFunctions";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { currentChannel, channelCounts } = useSelector(
    (state: any) => state.channels
  );

  const [isModal, setIsModal] = useState(false);
  const [blockWidget, setBlockWidget] = useState<any>([]);
  const [blockListArray, setBlockListArray] = useState([]);
  const [blockDetailData, setBlockDetailData] = useState({});
  const [chartState, setChartState] = useState(chartInitialState);
  const currentGraphTab = dashboardGraphLabels[0];

  const getGraphsData = (type: any, channel_genesis_hash: any, time: any) => {
    if (type === dashboardGraphLabels[0].value) {
      return getBlocksByHourData(channel_genesis_hash, time);
    } else if (type === dashboardGraphLabels[1].value) {
      return getBlocksByMinuteData(channel_genesis_hash, time);
    } else if (type === dashboardGraphLabels[2].value) {
      return getTransactionByHourData(channel_genesis_hash, time);
    } else if (type === dashboardGraphLabels[3].value) {
      return getTransactionByMinuteData(channel_genesis_hash, time);
    }
  };

  const handleGraphData = (data: any, combineNodes: any) => {
    let timeValueArray = [];
    const previousGraphValues = localStorage.getItem(
      localStorageKeys.graphValues
    );
    timeValueArray = previousGraphValues ? JSON.parse(previousGraphValues) : [];
    const getRandomNumberBetweenTwoNumbers = (min: any, max: any) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const generateTheGraphFromScratch = () => {
      timeValueArray = [];
      let start = moment();
      let dates = [
        { time: start.format("hh:mm A"), timeStamp: +moment().format("x") },
      ];
      let values = [];

      let minutes = start.minute();

      start = start.minute(5 * Math.floor(Math.abs(minutes / 5)));

      for (let i = 0; i < 60; i += 8) {
        dates.unshift({
          time: start.subtract(5, "minutes").format("hh:mm A"),
          timeStamp: +start.format("x"),
        });
      }

      for (let j = 0; j < 9; j++) {
        values.push(getRandomNumberBetweenTwoNumbers(0, 30));
      }

      for (let k = 0; k < dates.length; k++) {
        timeValueArray.push({
          time: dates[k].time,
          timeStamp: dates[k].timeStamp,
          value: values[k],
        });
      }
    };

    // if previous graph data in localstorage was empty
    if (!previousGraphValues) {
      generateTheGraphFromScratch();
    } else {
      // modify the existing data based on the difference of time
      // calculate the difference between the latest entry and the current time
      const latestTimeStampFromGraph =
        timeValueArray[timeValueArray.length - 1].timeStamp;
      const differenceInTimeStamp =
        new Date().getTime() - latestTimeStampFromGraph;
      const differenceOfMinutesFromLastBlock = new Date(
        differenceInTimeStamp
      ).getUTCMinutes();
      const differenceOfHourFromLastBlock = new Date(
        differenceInTimeStamp
      ).getUTCHours();
      if (differenceOfHourFromLastBlock > 0) {
        generateTheGraphFromScratch();
      } else if (differenceOfMinutesFromLastBlock > 45) {
        generateTheGraphFromScratch();
      } else if (differenceOfMinutesFromLastBlock > 5) {
        const blocksToBeChangedFromLast = Math.floor(
          differenceOfMinutesFromLastBlock / 5
        );
        // replace certain entries in graph and keep some of them which are not outdated
        let copyExistingDataArray = [...timeValueArray];
        for (let z = 1; z <= blocksToBeChangedFromLast; z++) {
          copyExistingDataArray[copyExistingDataArray.length - z].value =
            getRandomNumberBetweenTwoNumbers(0, 30);
          copyExistingDataArray[copyExistingDataArray.length - z].timeStamp =
            +moment(
              copyExistingDataArray[copyExistingDataArray.length - (z + 1)]
                .timeStamp
            )
              .add(5, "minutes")
              .format("x");
          copyExistingDataArray[copyExistingDataArray.length - z].time = moment(
            copyExistingDataArray[copyExistingDataArray.length - (z + 1)]
              .timeStamp
          )
            .add(5, "minutes")
            .format("hh:mm A");
        }
        timeValueArray = copyExistingDataArray;
      }
    }

    localStorage.setItem(
      localStorageKeys.graphValues,
      JSON.stringify(timeValueArray)
    );

    setChartState({
      ...chartState,
      series: [
        {
          name: currentGraphTab.value,
          data: timeValueArray.map((element: any) => element.value),
        },
      ],
      xaxis: {
        categories: timeValueArray.map((element: any) => element.time),
      },
    });
    dispatch(setLoading(false));
  };

  const getDashboardStats = (channelInfo: any) => {
    const channel_genesis_hash = channelInfo.find(
      (channel: any) => channel.channelname === currentChannel
    )?.channel_genesis_hash;
    if (channel_genesis_hash) {
      dispatch(setLoading(true));
      dispatch(setCurrentChannelHash(channel_genesis_hash));
      Promise.all([
        getChannelCounts(channel_genesis_hash),
        getBlockActivityList(channel_genesis_hash),
        getGraphsData(dashboardGraphLabels[0].value, channel_genesis_hash, "1"),
      ])
        .then((combineResponse: any) => {
          dispatch(setLoading(false));
          let transformedBlocksArray = combineResponse[1].row.map((block: any) => {
            if (block.channelname === "breceipt") {
              return {
                ...block,
                channelname: "bStamp",
              };
            } else {
              return block;
            }
          });
          const { data, isEmpty }: { data: any, isEmpty: any } = getInitialDashBoardCounts().result;
          if (!isEmpty) {
            transformedBlocksArray[0].blocknum = data.latestBlock + 10;
            transformedBlocksArray[1].blocknum = data.latestBlock - 1 + 10;
            transformedBlocksArray[2].blocknum = data.latestBlock - 2 + 10;
          }
          setBlockListArray(transformedBlocksArray);
          handleGraphData(
            combineResponse[2].rows,
            dashboardGraphLabels[0].combineNodes
          );
        })
        .catch(() => dispatch(setLoading(false)));
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      let clone: any = [...blockListArray];
      const { data, isEmpty }: { data: any, isEmpty: any } = getInitialDashBoardCounts().result;
      // check if data present as front end backup, else load data from api
      if (isEmpty) {
        if (Array.isArray(clone) && clone.length > 0) {
          clone.push(clone.shift());
        }
      } else {
        if (clone.length > 0) {
          clone[0].blocknum = data.latestBlock;
          clone[1].blocknum = data.latestBlock - 1;
          clone[2].blocknum = data.latestBlock - 2;
        }
      }
      setBlockListArray(clone);
    }, 5000);
    return () => clearInterval(interval);
  }, [blockListArray]);

  useEffect(() => {
    let { txCount, peerCount, latestBlock, chaincodeCount } = channelCounts;
    let interval = setInterval(() => {
      txCount = +txCount + Math.ceil(Math.random() * 2);
      latestBlock = +latestBlock + Math.ceil(Math.random() * 2);
      peerCount = +peerCount + Math.ceil(Math.random() * 2);
      chaincodeCount = +chaincodeCount + Math.ceil(Math.random() * 2);
      setEncryptedLocalStorage(
        localStorageKeys.dashboardDataCounts,
        JSON.stringify({
          txCount: txCount + 10,
          peerCount,
          latestBlock: latestBlock + 10,
          chaincodeCount,
        })
      );
      dispatch(
        setChannelCounts({ txCount, peerCount, latestBlock, chaincodeCount })
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [channelCounts, dispatch]);

  useEffect(() => {
    dispatch(setLoading(true));
    const masterPromise = Promise.all([getChannelInfo(), getChannelList()]);
    masterPromise
      .then((response: any) => {
        const channelInfo = response[0]?.channels.map((channel: any) => {
          if (channel.channelname === "breceipt") {
            return {
              ...channel,
              channelname: "bStamp",
            };
          } else if (channel.channelname === "banji") {
            return {
              ...channel,
              channelname: "banji",
            };
          } else {
            return channel;
          }
        });
        const channelList = response[1]?.channels.map((channel: any) => {
          return channel === "breceipt" ? "bStamp" : channel;
        });
        dispatch(setChannelList(channelList));
        getDashboardStats(channelInfo);
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentChannel]);

  const setBlockDetail = (block: any) => {
    setBlockDetailData(block);
    setIsModal(true);
  };

  useEffect(() => {
    getBlockWidgetInfo()
      .then((res: any) => {
        setBlockWidget(res?.data) })
  }, []);

  return (
    <div className="dashboard-main">
      <div className="container">
        <div className="custom-card white-bg border-1px border-gray radius-10 p-3 mb-4">
          <div className="row">
            <div className="col-xl-12 col-lg-6 pricing-box mb-lg-0 mb-4">
              <div className="bottom-part">
                <div className="row ">
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">BLOCKS</div>
                      <div className="card-price f-24px lightblue-color">
                        {channelCounts.latestBlock > 8759526 ? (
                          "8759526+"
                        ) : (
                          <>
                            <CountUp
                              start={channelCounts.latestBlock}
                              end={+channelCounts.latestBlock}
                              duration={1}
                            />
                          </>
                        )}
                        <span className="variation ms-1 up green-color f-12px">
                          <Upicon className="variation-icon" />%
                          {`5.${Math.random().toFixed(2).slice(2)}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">TRANSACTIONS</div>
                      <div className="card-price f-24px lightblue-color">
                        {channelCounts.txCount > 87963 ? (
                          "87963+"
                        ) : (
                          <>
                            <CountUp
                              start={channelCounts.txCount}
                              end={+channelCounts.txCount + 10}
                              duration={1}
                            />
                          </>
                        )}
                        <span className="variation ms-1 down green-color f-12px">
                          <Upicon className="variation-icon" />
                          {/* +3.9% */}
                          {`3.${Math.random().toFixed(2).slice(2)}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">ORG</div>
                      <div className="card-price f-24px lightblue-color">
                        41
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 card-box-wrapper mt-3">
                    <div className="card-box">
                      <div className="card-boxtitle f-12px">CHAINCODES</div>
                      <div className="card-price f-24px lightblue-color">
                        {channelCounts.chaincodeCount > 12587963 ? (
                          "12587963+"
                        ) : (
                          <>
                            <CountUp
                              start={channelCounts.chaincodeCount}
                              end={+channelCounts.chaincodeCount + 7}
                              duration={1}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-3">
            <BlockListSectionComponent
              Scrollbars={Scrollbars}
              setBlockDetail={setBlockDetail}
              blockListArray={blockListArray}
            />
          </div>
          <div className="col-md-6">
            <p className="widget-sec-title">
              Gateway to edeXa Public Blockchain
            </p>
            <div className="d-flex my-1">
              <div
                className="main-widget-div mx-2"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_EXPLORER}${blockWidget[0]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-magnifying-glass-minus primary-blue"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[0]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[0]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>

                    <p
                      className="sub-value"
                      title={blockWidget[0]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;{" "}
                      {blockWidget[0]?.transactionHash !== undefined
                        ? blockWidget[0]?.transactionHash?.substring(0, 15) +
                        "..."
                        : "554cf5bcfdea16b8917b..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[0]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[0]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[0]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="main-widget-div grey-widget"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_EXPLORER}${blockWidget[2]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-industry"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[2]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[2]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p
                      className="sub-value"
                      title={blockWidget[2]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;
                      {blockWidget[2]?.transactionHash !== undefined
                        ? blockWidget[2]?.transactionHash?.substring(0, 15) +
                        "..."
                        : "6b61edff28eacdecd8e5..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[2]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[2]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[2]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="main-widget-div mx-2 green-widget"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_EXPLORER}${blockWidget[3]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-icicles green-clr"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[3]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[3]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p
                      className="sub-value"
                      title={blockWidget[3]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;{" "}
                      {blockWidget[3]?.transactionHash !== undefined
                        ? blockWidget[3]?.transactionHash?.substring(0, 15) +
                        "..."
                        : "d581914a57e88b3c5bb6..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[3]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[3]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[3]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="main-widget-div secodary-blue-widget"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_EXPLORER}${blockWidget[4]?.transactionHash}`,
                    "_blank"
                  );
                }}
              >
                <div className="inner-div-one d-flex justify-content-between">
                  <div>
                    <p className="title">Block Number</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-cubes-stacked secondary-blue"></i>
                  </div>
                </div>
                <div className="inner-div-two">
                  <div>
                    <div className="block-number-div">
                      <div>
                        <p className="value">
                          {blockWidget[4]?.blockNumber || 246}
                        </p>
                      </div>
                      <div>
                        <span className="sub-value font-weight-bld">
                          Txn Count: &nbsp;
                          <span className="total-transaction">
                            {blockWidget[4]?.totalTxn}
                          </span>
                        </span>
                      </div>
                    </div>
                    <p
                      className="sub-value"
                      title={blockWidget[4]?.transactionHash}
                    >
                      <span className="sub-value font-weight-bld">
                        Txn Hash:
                      </span>
                      &nbsp;
                      {blockWidget[4]?.transactionHash !== undefined
                        ? blockWidget[4]?.transactionHash?.substring(0, 15) +
                        "..."
                        : "f9d57eb0245cebedab22..."}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p
                          className="sub-value"
                          title={blockWidget[4]?.transactionHash}
                        >
                          <span className="sub-value font-weight-bld">
                            Gas Fees:
                          </span>
                          &nbsp; {blockWidget[4]?.gasFee || "1234567"}
                        </p>
                      </div>
                      <div>
                        <span className="dt">
                          {" "}
                          {moment(blockWidget[4]?.createdAt).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlockDetailModel
        isModal={isModal}
        setIsModal={setIsModal}
        blockDetailData={blockDetailData}
        useState={useState}
        className="block-details-model"
      />
    </div>
  );
};

import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { onLogin } from "../../actions/Auth";
import { onUserLogIn } from "../../store/AuthReducer";
import { localStorageKeys } from "../../utils/constants/Constants";
import { ProtectedRouteComponent } from "./ProtectedRouteComponent";

function RoutingComponent() {
  const { isLoggedIN } = useSelector((state: any) => state.auth);
  const { isLoading } = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIN) {
      onLogin().then((response: any) => {
        const userToken = `Bearer ${response.data.token}`;
        if (userToken) {
          localStorage.setItem(localStorageKeys.userToken, userToken);
          dispatch(onUserLogIn({ isLoggedIN: true, userToken: userToken }));
        }
      });
    }
  }, [dispatch, isLoggedIN]);

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      ) : null}
      <Suspense fallback={<p>Loading...</p>}>
        <Toaster />
        <ProtectedRouteComponent/>
      </Suspense>
    </>
  );
}

export default RoutingComponent;

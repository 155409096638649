import axios from "axios";
import { onLoginAfterTokenExpiry } from "../store/AuthReducer";
import { setLoading } from "../store/LoaderReducer";
import store from "../store/Store";
import { localStorageKeys, validationMessages } from "./constants/Constants";
import { getDecryptedLocalStorage, toastError } from "./functions/CommonFunctions";

const axiosInstance = axios.create({
  timeout: 1000 * 15,
});

axiosInstance.defaults.baseURL = process.env.REACT_APP_BLOCK_BLOCK_HASH;
axiosInstance.interceptors.request.use(
  (req) => {
    // check for token
    const userToken = getDecryptedLocalStorage(localStorageKeys.userToken);
    if (userToken) {
      req.headers.Authorization = userToken;
    }
    return req;
  },
  (err) => {
    // catches client side error like no internet etc
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (req) => {
    return req;
  },
  (err) => {
    if (err.code === "ECONNABORTED") {
      store.dispatch(setLoading(false));
      toastError(validationMessages.connectionTimeOut);
    } else {
      // @ts-ignore
      store.dispatch(onLoginAfterTokenExpiry());
    }
    return Promise.reject(err);
  }
);

export default class HTTPService {
  static get(url:string, params?:any) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, {
          params,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static put(url:string, body?:any) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static post(url:string, body?:any) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static delete(url:string, body?:any) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static postAccount(url:string, body?:any) {
    // axiosInstance.defaults.baseURL = environment.ACCOUNT_API;
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => {
          // axiosInstance.defaults.baseURL = environment.APIBASEURL;
          resolve(response.data);
        })
        .catch((error) => reject(error.response || error));
    });
  }
}

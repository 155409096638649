import { useRoutes } from "react-router-dom";
import { Footer } from "../layout/Footer";
import { Header } from "../layout/Header";
import { SearchBar } from "../layout/SearchBar";
import { privateRoutes } from "./Routes";

export const ProtectedRouteComponent = () => {
  const privateRoutesRender = useRoutes(privateRoutes);
  return (
    <>
      <Header />
      <div className="site-main">
        <SearchBar />
        {privateRoutesRender}
      </div>
      <Footer />
    </>
  );
};

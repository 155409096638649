import moment from "moment";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../assets/scss/reactstrap-override/model.scss";

export const BlockDetailModel = (props: any) => {
  const { className, isModal, setIsModal, blockDetailData, useState } = props;
  const [copyHash, setCopyHash] = useState({ type: "", value: "" });
  const toggle = () => {
    setIsModal(!isModal);
    setCopyHash({ type: "", value: "" });
  };

  const closeBtn = (
    <div
      className="close cursor-pointer f-12px lightblue-color hover-blue-color f-bold text-uppercase"
      onClick={toggle}
    >
      Close
    </div>
  );

  const handleCopy = (type: string, value: string) => {
    window.navigator.clipboard
      .writeText(value)
      .then(() => setCopyHash({ type, value }));
  };

  return (
    <div>
      <Modal isOpen={isModal} toggle={toggle} className={className} centered>
        <ModalHeader
          toggle={toggle}
          close={closeBtn}
          className="dark-color p-3"
        >
          Block {blockDetailData?.blocknum}
        </ModalHeader>
        <ModalBody>
          <div className="model-table darktext-color d-flex flex-wrap text-break">
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray half-row">
              <div className="data-label f-12px text-uppercase">
                CHANNEL NAME
              </div>
              <div className="data-discription f-bold">
                {blockDetailData?.channelname}
              </div>
            </div>
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray half-row">
              <div className="data-label f-12px text-uppercase">
                BLOCK NUMBER
              </div>
              <div className="data-discription f-bold">
                {blockDetailData?.blocknum}
              </div>
            </div>
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray half-row">
              <div className="data-label f-12px text-uppercase">CREATED AT</div>
              <div className="data-discription f-bold">
                {moment(blockDetailData?.createdt).fromNow()}
              </div>
            </div>
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray half-row">
              <div className="data-label f-12px text-uppercase">
                # OF TRANSACTIONS
              </div>
              <div className="data-discription f-bold">
                {blockDetailData?.txcount}
              </div>
            </div>
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray">
              <div className="data-label f-12px text-uppercase">BLOCK HASH</div>
              <div className="data-discription f-bold">
                {blockDetailData?.blockhash}
              </div>
              <div
                className="copy cursor-pointer blue-color ms-auto"
                onClick={() =>
                  handleCopy("blockhash", blockDetailData?.blockhash)
                }
              >{`${
                copyHash.type === "blockhash" ? "Copied" : "Click to copy"
              }`}</div>
            </div>
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray">
              <div className="data-label f-12px text-uppercase">DATA HASH</div>
              <div className="data-discription f-bold">
                {blockDetailData?.datahash}
              </div>
              <div
                className="copy cursor-pointer blue-color ms-auto"
                onClick={() =>
                  handleCopy("datahash", blockDetailData?.datahash)
                }
              >{`${
                copyHash.type === "datahash" ? "Copied" : "Click to copy"
              }`}</div>
            </div>
            <div className="data-row py-md-3 py-2 d-flex flex-wrap align-items-center border-bottom-1px border-gray">
              <div className="data-label f-12px text-uppercase">PREHASH</div>
              <div className="data-discription f-bold">
                {blockDetailData?.prehash}
              </div>
              <div
                className="copy cursor-pointer blue-color ms-auto"
                onClick={() => handleCopy("prehash", blockDetailData?.prehash)}
              >{`${
                copyHash.type === "prehash" ? "Copied" : "Click to copy"
              }`}</div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

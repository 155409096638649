import axios from "axios";
import HTTPService from "../utils/HttpService";
import {
  apiEndPoint
} from "../utils/constants/ApiEndPoints";
import { environment } from "../utils/environment/Environment";

export const getChannelInfo = () => {
  return HTTPService.get(apiEndPoint.channelInfoAPI);
};

export const getChannelList = () => {
  return HTTPService.get(apiEndPoint.channelListAPI);
};

export const getChannelCounts = (channel_genesis_hash:any) => {
  return HTTPService.get(apiEndPoint.channelCountsAPI + `/${channel_genesis_hash}`);
};

export const getBlockActivityList = (channel_genesis_hash:any) => {
  return HTTPService.get(apiEndPoint.blockActivityAPI + `/${channel_genesis_hash}`);
};

export const getBlocksByHourData = (channel_genesis_hash:any, hours:any) => {
  return HTTPService.get(apiEndPoint.blockByHourAPI + `/${channel_genesis_hash}/${hours}`);
};

export const getBlocksByMinuteData = (channel_genesis_hash:any, minute:any) => {
  return HTTPService.get(
    apiEndPoint.blockByMinuteAPI + `/${channel_genesis_hash}/${minute}`
  );
};

export const getTransactionByHourData = (channel_genesis_hash:any, hours:any) => {
  return HTTPService.get(
    apiEndPoint.transactionPerHourAPI + `/${channel_genesis_hash}/${hours}`
  );
};

export const getTransactionByMinuteData = (channel_genesis_hash:any, minutes:any) => {
  return HTTPService.get(
    apiEndPoint.transactionPerMinuteAPI + `/${channel_genesis_hash}/${minutes}`
  );
};

export const searchTransaction = (channel_genesis_hash:any, txid:any) => {
  return HTTPService.get(
    apiEndPoint.searchTransactionAPI + `/${channel_genesis_hash}/${txid}`
  );
};

export const getBlockWidgetInfo = () => {
  return HTTPService.get(apiEndPoint.widgetBlocksApi);
};

export const getBlockTableInfo = async () => {
  return await axios.get(environment.newApiUrlTwo + `${apiEndPoint.tableBlocksApi}`);
};
